<template>
  <div class="form-list-wrap">
    <!-- 1단계 : 회사정보 -->
    <div
      v-if="!accApplyPgmMngDetail.isEmptyStep1()"
      class="form-list">
      <h6 class="form-list-title">
        {{ $t('content.accelerating.academy.Common.txt02') }}
      </h6>
      <ul>
        <!-- 업체명 -->
        <li
          v-if="accApplyPgmMngDetail.companyNameYn || accApplyPgmMngDetail.companyNameEnYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt01') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">{{ accInfo.company_name || '-' }} / {{ accInfo.company_name_en || '-' }}</span>
        </li>

        <!-- 사업자등록번호 -->
        <li
          v-if="accApplyPgmMngDetail.companyRegNoYn"
        >
          <span class="list-title">{{ $t('content.accelerating.academy.CompanyInfo.txt04') }}</span>
          <span class="list-data">{{ accInfo.company_reg_no || '-' }}</span>
        </li>

        <!-- 법인 등록번호 -->
        <li
          v-if="accApplyPgmMngDetail.corporationRegNoYn"
        >
          <span class="list-title">{{ $t('content.accelerating.academy.CompanyInfo.txt06') }}</span>
          <span class="list-data">{{ accInfo.corporation_reg_no || '-' }}</span>
        </li>

        <!-- 벤처기업 인증번호 -->
        <li
          v-if="accApplyPgmMngDetail.ventureCertNoYn"
        >
          <span class="list-title">{{ $t('content.accelerating.academy.CompanyInfo.txt08') }}</span>
          <span class="list-data">{{ accInfo.venture_cert_no || '-' }}</span>
        </li>

        <!-- 설립연도 -->
        <li
          v-if="accApplyPgmMngDetail.buildYearYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt10') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">{{ buildDate }}</span>
        </li>

        <!-- 대표자, 공동대표 -->
        <li
          v-if="accApplyPgmMngDetail.ceoNameYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt14') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <span
              v-for="(ceoInfo, index) in accCeoInfo"
              :key="index"
            class="block ">{{ ceoInfo.ceo_name }}</span>
          </span>
        </li>

        <!-- 대표자 연락처 -->
        <li
          v-if="accApplyPgmMngDetail.ceoCellphoneYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.Common.txt10') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">{{ ceoCellphone }}</span>
        </li>

        <!-- 대표자 이메일 -->
        <li
          v-if="accApplyPgmMngDetail.ceoCellphoneYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt50') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">{{ accInfo.ceo_email || '-'}}</span>
        </li>

        <!-- 대표자 최종학력 -->
        <li
          v-if="accApplyPgmMngDetail.ceoFinalEnducationYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt51') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">{{ accInfo.ceo_final_enducation || '-' }}</span>
        </li>

        <!-- 대표자 주요이력 -->
        <li
          v-if="accApplyPgmMngDetail.ceoMajorCareerYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt52') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <span class="block">{{ accInfo.ceo_major_career || '-'}}</span>
          </span>
        </li>

        <!-- 현재 직원 수 -->
        <li
          v-if="accApplyPgmMngDetail.employeeNumberYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt19') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            {{ accInfo.employee_number || '-' }} {{$t('content.accelerating.academy.DisplayInfo.txt04')}}
          </span>
        </li>

        <!-- 직원추가계획 -->
        <li
          v-if="accApplyPgmMngDetail.employeeAddTagYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt21') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            {{ accInfo.employee_add_tag || '-' }} {{$t('content.accelerating.academy.DisplayInfo.txt04')}}
          </span>
        </li>

        <!-- 신청구분 -->
        <li
          v-if="accApplyPgmMngDetail.applicationCategoryYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt43') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <!-- 개인사업자 -->
          <span
            v-if="accInfo.application_category === '0'"
            class="list-data">{{ $t('content.accelerating.academy.CompanyInfo.txt45') }}</span>
          <!-- 예비창업자 -->
          <span
            v-if="accInfo.application_category === '1'"
            class="list-data">{{ $t('content.accelerating.academy.CompanyInfo.txt46') }}</span>
        </li>

        <!-- 멤버구성 -->
        <li
          v-if="accApplyPgmMngDetail.memberConfigurationYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt44') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <!-- 개인 -->
          <span
            v-if="accInfo.member_configuration === '0'"
            class="list-data">{{ $t('content.accelerating.academy.CompanyInfo.txt47') }}</span>
          <!-- 팀 (팀 인원)-->
          <span
            v-if="accInfo.member_configuration === '1'"
            class="list-data">
            {{ $t('content.accelerating.academy.CompanyInfo.txt48') }}
            ({{ accInfo.member_configuration_input || '-' }} {{ $t('content.accelerating.academy.DisplayInfo.txt04') }})
          </span>
          <span v-if="accInfo.check_developer && (accInfo.NUMBER_DEVELOPER !== undefined)">
            개발자({{accInfo.NUMBER_DEVELOPER}})
          </span>
          <span v-if="accInfo.check_director && (accInfo.NUMBER_DIRECTOR !== undefined)">
            기획자({{accInfo.NUMBER_DIRECTOR}})
          </span>
          <span v-if="accInfo.check_designer && (accInfo.NUMBER_DESIGNER !== undefined)">
            디자이너({{accInfo.NUMBER_DESIGNER}})
          </span>
        </li>

        <!-- 홈페이지 / SNS -->
        <li
          v-if="accApplyPgmMngDetail.companyHomepageUrlYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt24') }}
          </span>
          <span class="list-data">
            <!-- 홈페이지 -->
            <span
              v-if="accInfo.company_homepage_url"
              class="block">{{ accInfo.company_homepage_url }}</span>
            <!-- SNS -->
            <span
              v-if="accInfo.company_insta_url"
              class="block">{{ accInfo.company_insta_url }}</span>
          </span>
        </li>

        <!-- 수상경력 -->
        <li
          v-if="accApplyPgmMngDetail.accAwardCareerInfoYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt28') }}
          </span>
          <span class="list-data">
            <span
              v-for="(awardCareerInfo, index) in accAwardCareerInfo"
              :key="index"
              class="block">
              {{ formatDate(awardCareerInfo.award_date) }} /
              {{ awardCareerInfo.competition_name || '-' }} /
              {{ awardCareerInfo.host_organization || '-' }} /
              {{ awardCareerInfo.award || '-' }}
            </span>
          </span>
        </li>

        <!-- 보유 지적 재산권 -->
        <li
          v-if="accApplyPgmMngDetail.accPropertyInfoYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt34') }}
          </span>
          <span class="list-data">
            <span
              v-for="(propertyInfo, index) in accPropertyInfo"
              :key="index"
              class="block">
              <!-- 명칭 -->
              {{ propertyInfo.property_name || '-' }} /
              <!-- 증명서번호 -->
              {{ propertyInfo.property_no || '-' }} /

              <!-- 등록 -->
              <span
                v-if="propertyInfo.reg_app_code === '0'"
              >{{ $t('content.accelerating.academy.CompanyInfo.txt37') }}</span>
              <!-- 출원 -->
              <span
                v-if="propertyInfo.reg_app_code === '1'"
              >{{ $t('content.accelerating.academy.CompanyInfo.txt38') }}</span>
              /

              <!-- 등록/출원 날짜 -->
              {{ formatDate(propertyInfo.reg_app_date) }}
            </span>
          </span>
        </li>

        <!-- 보유 허가 및 등록 현황 -->
        <li
          v-if="accApplyPgmMngDetail.accLicenseInfoYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.CompanyInfo.txt40') }}
          </span>
          <span class="list-data">
            <span
              v-for="(licenseInfo, index) in accLicenseInfo"
              :key="index"
              class="block">
              <!-- 허가 명칭 -->
              {{ licenseInfo.license_name || '-' }} /
              <!-- 허가 번호 -->
              {{ licenseInfo.license_no || '-' }}
            </span>
          </span>
        </li>
      </ul>
    </div>
    <!-- // 1단계 : 회사정보 -->

    <!-- 2단계 : 멤버정보 - 멤버 -->
    <div
      v-if="!accApplyPgmMngDetail.isEmptyStep2()"
      class="form-list">
      <h6 class="form-list-title">
        {{ $t('content.accelerating.academy.Common.txt03') }}
      </h6>
      <ul>
        <li
          v-if="accApplyPgmMngDetail.nameYn || accApplyPgmMngDetail.workerKindYn ||
            accApplyPgmMngDetail.positionYn || accApplyPgmMngDetail.careerYn ||
            accApplyPgmMngDetail.memberCellphoneYn || accApplyPgmMngDetail.memberEmailYn"
        >
          <!-- (AC114) Corporate ACC-Hyundai인 경우 : '멤버'대신에 '담당자'로 표시 -->
          <template v-if="accApplyPgmMngDetail.accKind === 'AC114'">
            <span class="list-title">
              {{ $t('commonCode.PL1004') }}
              <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
            </span>
          </template>
          <!-- 그 외의 경우 : '멤버'로 표시 -->
          <template v-else>
            <span class="list-title">
              {{ $t('content.accelerating.academy.MemberInfo.txt01') }}
              <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
            </span>
          </template>
          <span class="list-data">
            <span
              v-for="(memberInfo, index) in accMemberInfo"
              :key="index"
              class="block">
              <!-- 이름 -->
              <template
                v-if="accApplyPgmMngDetail.nameYn"
              >
                {{ memberInfo.name || '-' }} /
              </template>
              <template
                v-if="accApplyPgmMngDetail.member_gender && accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
              >
                <!-- 남 -->
                <span
                  v-if="memberInfo.member_gender === '0'"
                >{{ $t('content.accelerating.academy.MemberInfo.txt21') }}</span>
                <!-- 여 -->
                <span
                  v-if="memberInfo.member_gender === '1'"
                >{{ $t('content.accelerating.academy.MemberInfo.txt22') }}</span>
                /
              </template>
              <!-- 소속 -->
              <template
                v-if="accApplyPgmMngDetail.workerKindYn"
              >
                {{ memberInfo.department || '-' }} /
              </template>
              <!-- 직책 -->
              <template
                v-if="accApplyPgmMngDetail.positionYn"
              >
                {{ memberInfo.position || '-' }} /
              </template>
              <template
                v-if="accApplyPgmMngDetail.positionYn &&
                memberInfo.position === $t('content.accelerating.academy.MemberInfo.txt24')"
              >
                {{`보유기술 스택(${memberInfo.skills.join(', ') || '-' })`}} /
              </template>
              <template
                v-if="accApplyPgmMngDetail.positionYn &&
                memberInfo.position === $t('content.accelerating.academy.MemberInfo.txt24')"
              >
                {{`프로그래밍 활용 능력(${memberInfo.programing_level})` || '-' }} /
              </template>
              <!-- 생년월일 -->
              <template
                v-if="accApplyPgmMngDetail.careerYn"
              >
                {{ memberInfo.career || '-' }} /
              </template>

              <!-- 휴대폰번호 -->
              <template
                v-if="accApplyPgmMngDetail.memberCellphoneYn"
              >
                {{ memberInfo.cellphone || '-' }} /
              </template>

              <!-- 이메일 -->
              <template
                v-if="accApplyPgmMngDetail.memberEmailYn"
              >
                {{ memberInfo.email || '-' }} /
              </template>

              <!-- MBTI -->
              <template
                v-if="accApplyPgmMngDetail.mbtiYn"
              >
                {{ memberInfo.mbti || '-' }} /
              </template>

              <!-- 백신 -->
              <template
                v-if="accApplyPgmMngDetail.vaccineYn"
              >
                {{ `백신 ${memberInfo.vaccine === '0' ? '미접종' : '접종'}` || '-'}}
              </template>
            </span>
          </span>
        </li>
      </ul>
    </div>
    <!-- // 2단계 : 멤버정보 - 멤버 -->

    <!-- 2단계 : 멤버정보 - 창업 프로그램 참여경험 -->
    <div
      v-if="accApplyPgmMngDetail.accStartPgJoinExpYn"
      class="form-list">
      <h6 class="form-list-title">
        {{ $t('content.accelerating.academy.MemberInfo.txt07') }}
      </h6>
      <ul>
        <li>
          <!-- 프로그램 -->
          <span class="list-title">{{ $t('content.accelerating.academy.MemberInfo.txt10') }}</span>
          <span class="list-data">
            <span
              v-for="(startPgJoinExp, index) in accStartPgJoinExp"
              :key="index"
              class="block">
              <!-- 시작일 -->
              {{ formatDate(startPgJoinExp.join_start_date) }} ~
              <!-- 종료일 -->
              {{ formatDate(startPgJoinExp.join_end_date) }} /
              <!-- 프로그램 -->
              {{ startPgJoinExp.start_pg_name || '-' }} /
              <!-- 수상 -->
              <span
                v-if="startPgJoinExp.aword_yn === '1'"
              >
                {{ $t('content.accelerating.academy.MemberInfo.txt11') }} :
                <!-- 수여 혜택 -->
                {{ startPgJoinExp.benefit || '-' }}
              </span>
            </span>
          </span>
        </li>
      </ul>
    </div>
    <!-- // 2단계 : 멤버정보 - 창업 프로그램 참여경험 -->

    <!-- 2단계 : 멤버정보 - 멘토 -->
    <div
      v-if="accApplyPgmMngDetail.accMentorInfoYn"
      class="form-list">
      <h6 class="form-list-title">
        {{ $t('content.accelerating.academy.DisplayInfo.txt05') }}
      </h6>
      <ul>
        <li>
          <!-- 멘토 -->
          <span class="list-title">{{ $t('content.accelerating.academy.MemberInfo.txt14') }}</span>
          <span class="list-data">
            <span
              v-for="(mentorInfo, index) in accMentorInfo"
              :key="index"
              class="block">
              <!-- 멘토 이름 -->
              {{ mentorInfo.mento_name || '-' }} /
              <!-- 멘토 주요 이력 -->
              {{ mentorInfo.mento_career || '-' }}
            </span>
          </span>
        </li>
      </ul>
    </div>
    <!-- // 2단계 : 멤버정보 - 멘토 -->

    <!-- 3단계 : 대표서비스 -->
    <div
      v-if="!accApplyPgmMngDetail.isEmptyStep3()"
      class="form-list">
      <h6 class="form-list-title">
        {{ $t('content.accelerating.academy.Common.txt04') }}
      </h6>
      <ul>
        <!-- 서비스명 -->
        <li
          v-if="accApplyPgmMngDetail.serviceNameYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.ServiceInfo.txt01') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">{{ accInfo.service_name || '-' }}</span>
        </li>

        <!-- 서비스 소개 -->
        <li
          v-if="accApplyPgmMngDetail.serviceIntroduceYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.ServiceInfo.txt03') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">{{ accInfo.service_introduce || '-' }}</span>
        </li>

        <!-- 서비스 차별점 -->
        <li
          v-if="accApplyPgmMngDetail.serviceDiffrenceYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.ServiceInfo.txt06') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">{{ accInfo.service_diffrence || '-' }}</span>
        </li>

        <!-- 서비스/채널 URL -->
        <li
          v-if="accApplyPgmMngDetail.serviceUrlYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.ServiceInfo.txt08') }}
          </span>
          <span class="list-data">{{ accInfo.service_url || '-' }}</span>
        </li>

        <!-- SNS URL -->
        <li
          v-if="accApplyPgmMngDetail.serviceFacebookUrlYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.ServiceInfo.txt10') }}
          </span>
          <span class="list-data">{{ accInfo.service_facebook_url || '-' }}</span>
        </li>

        <!-- 홍보영상/기사 URL -->
        <li
          v-if="accApplyPgmMngDetail.promotionUrl1Yn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.ServiceInfo.txt11') }}
          </span>
          <span class="list-data">
            {{ accInfo.promotion_url1 }}
            {{ accInfo.promotion_url2 }}
            {{ accInfo.promotion_url3 }}
          </span>
        </li>
      </ul>
    </div>
    <!-- // 3단계 : 대표서비스 -->

    <!-- 4단계 : 투자내용 -->
    <div
      v-if="!accApplyPgmMngDetail.isEmptyStep4()"
      class="form-list">
      <h6 class="form-list-title">
        {{ $t('content.accelerating.academy.Common.txt05') }}
      </h6>
      <ul>
        <!-- 투자이력 없음인 경우 : 투자이력 없음 표시 -->
        <li v-if="accInfo.invest_history_check === '1'">
          <!-- 투자이력 없음 -->
          <span class="list-title">
            {{ $t('content.accelerating.academy.InvestmentInfo.txt08') }}
          </span>
        </li>
        <!-- 투자입력 없음이 아닌 경우 : 투자유치 현황 표시 -->
        <li v-else>
          <!-- 투자유치 현황 -->
          <!-- * (AC114) Corporate ACC-Hyundai인 경우에는 : 투자유치 현황 필수입력 -->
          <span class="list-title">
            {{ $t('content.accelerating.academy.InvestmentInfo.txt01') }}
            <em
              v-if="accApplyPgmMngDetail.accKind === 'AC114'"
              class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <span
              v-for="(investInfo, index) in accInvestInfo"
              :key="index"
              class="block">
              <!-- 투자일자 -->
              <template
                v-if="accApplyPgmMngDetail.investDateYn"
              >
                {{ formatDate(investInfo.invest_date) }} /
              </template>

              <!-- 투자기관 -->
              <template
                v-if="accApplyPgmMngDetail.investOrgYn"
              >
                {{ investInfo.invest_org || '-' }} /
              </template>

              <!-- 투자금액 -->
              <template
                v-if="accApplyPgmMngDetail.investAmountYn"
              >
                {{ investInfo.invest_amount || '-' }} {{ $t('content.accelerating.academy.DisplayInfo.txt06') }} /
              </template>

              <!-- 라운드 -->
              <template
                v-if="accApplyPgmMngDetail.investRoundYn"
              >
                {{ investInfo.invest_round || '-' }}
              </template>
            </span>
          </span>
        </li>
      </ul>
    </div>
    <!-- // 4단계 : 투자내용 -->

    <!-- 5단계 : 기타입력 -->
    <div
      v-if="!accApplyPgmMngDetail.isEmptyStep5()"
      class="form-list">
      <h6 class="form-list-title">
        {{ $t('content.accelerating.academy.DisplayInfo.txt07') }}
      </h6>
      <ul>
        <!-- 파일첨부 -->
        <li
          v-if="accApplyPgmMngDetail.comRegNoFileYn || accApplyPgmMngDetail.stockholderFileYn ||
            accApplyPgmMngDetail.registerFileYn || accApplyPgmMngDetail.propertyFileYn ||
            accApplyPgmMngDetail.etcFileYn || accApplyPgmMngDetail.etcFile2Yn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.OtherInfo.txt01') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <!-- 사업자등록증 파일첨부 -->
            <span
              v-if="accApplyPgmMngDetail.comRegNoFileYn && accInfo.com_reg_no_file"
              class="block"
            >
              <a
                href="javascript:void(0);"
                @click="downloadEtcInfoFile(accInfo.com_reg_no_file, accInfo.com_reg_no_file_name)"
              >{{ accInfo.com_reg_no_file_name }}</a>
            </span>
            <!-- 주주명부 파일첨부 -->
            <span
              v-if="accApplyPgmMngDetail.stockholderFileYn && accInfo.stockholder_file"
              class="block"
            >
              <a
                href="javascript:void(0);"
                @click="downloadEtcInfoFile(accInfo.stockholder_file, accInfo.stockholder_file_name)"
              >{{ accInfo.stockholder_file_name }}</a>
            </span>
            <!-- 등기부등본 파일첨부 -->
            <span
              v-if="accApplyPgmMngDetail.registerFileYn && accInfo.register_file"
              class="block"
            >
              <a
                href="javascript:void(0);"
                @click="downloadEtcInfoFile(accInfo.register_file, accInfo.register_file_name)"
              >{{ accInfo.register_file_name }}</a>
            </span>
            <!-- 지적재산권 사본 파일첨부 -->
            <span
              v-if="accApplyPgmMngDetail.propertyFileYn && accInfo.property_file"
              class="block"
            >
              <a
                href="javascript:void(0);"
                @click="downloadEtcInfoFile(accInfo.property_file, accInfo.property_file_name)"
              >{{ accInfo.property_file_name }}</a>
            </span>
            <!-- 기타1 파일첨부 -->
            <span
              v-if="accApplyPgmMngDetail.etcFileYn && accInfo.etc_file"
              class="block"
            >
              <a
                href="javascript:void(0);"
                @click="downloadEtcInfoFile(accInfo.etc_file, accInfo.etc_file_name)"
              >{{ accInfo.etc_file_name }}</a>
            </span>
            <!-- 기타2 파일첨부 -->
            <span
              v-if="accApplyPgmMngDetail.etcFile2Yn && accInfo.etc_file2"
              class="block"
            >
              <a
                href="javascript:void(0);"
                @click="downloadEtcInfoFile(accInfo.etc_file2, accInfo.etc_file_name2)"
              >{{ accInfo.etc_file_name2 }}</a>
            </span>
          </span>
        </li>
        <!-- // 파일첨부 -->

        <!-- (AC102) Lifestyle-Digital Healthcare / Wellness인 경우 : 지원영역 표시 -->
        <li
          v-if="accApplyPgmMngDetail.accKind === 'AC102'">
          <span class="list-title">
            {{ $t('content.accelerating.academy.OtherInfo.txt22') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <!-- Digital Healthcare -->
            <span
              v-if="accInfo.acc_addition_kind === '0'"
              class="block"
            >{{$t('content.accelerating.academy.OtherInfo.txt63')}}</span>
            <!-- Wellness -->
            <span
              v-if="accInfo.acc_addition_kind === '1'"
              class="block"
            >{{$t('content.accelerating.academy.OtherInfo.txt64')}}</span>
          </span>
        </li>

        <!-- 원하는 지원 항목 -->
        <li
          v-if="accApplyPgmMngDetail.codeSupportItemYn"
        >

          <!-- 원하는 지원 항목 : (AC107) Corporate ACC-Eland인 경우 -->
          <template v-if="accApplyPgmMngDetail.accKind === 'AC107'">
            <span class="list-title">
              {{ $t('content.accelerating.academy.OtherInfo.txt07') }}
              <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
            </span>
            <span class="list-data">
              <!-- 리테일테크 -->
              <span
                v-if="accInfo.code_support_corporateitem === '1'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt24')}}</span>
              <!-- 신규 리테일 컨텐츠 -->
              <!-- <span
                v-if="accInfo.code_support_corporateitem === '2'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt25')}}</span> -->
              <!-- 신규 비즈니스 모델 -->
              <!-- <span
                v-if="accInfo.code_support_corporateitem === '3'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt26')}}</span> -->
              <!-- 컨텐츠 비즈니스 -->
              <span
                v-if="accInfo.code_support_corporateitem === '4'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt61')}}</span>
              <!-- 신규 플랫폼 -->
              <span
                v-if="accInfo.code_support_corporateitem === '5'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt62')}}</span>
            </span>
          </template>
          <!-- // 원하는 지원 항목 : (AC107) Corporate ACC-Eland인 경우 -->

          <!-- 원하는 지원 항목 : (AC108) GEP (Global Expansion Program)인 경우 -->
          <template v-else-if="accApplyPgmMngDetail.accKind === 'AC108'">
            <span class="list-title">
              {{ $t('content.accelerating.academy.OtherInfo.txt07') }}
              <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
            </span>
            <span class="list-data">
              <!-- 중국 -->
              <span
                v-if="accInfo.code_support_gepitem === '1'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt28')}}</span>
              <!-- 베트남 -->
              <span
                v-if="accInfo.code_support_gepitem === '2'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt29')}}</span>
              <!-- 일본 -->
              <span
                v-if="accInfo.code_support_gepitem === '3'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt30')}}</span>
              <!-- 인도네시아 -->
              <span
                v-if="accInfo.code_support_gepitem === '4'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt31')}}</span>
            </span>
          </template>
          <!-- // 원하는 지원 항목 : (AC108) GEP (Global Expansion Program)인 경우 -->

          <!-- 원하는 지원 항목 : (AC114) Corporate ACC-Hyundai인 경우 -->
          <template v-else-if="accApplyPgmMngDetail.accKind === 'AC114'">
            <span class="list-title">
              {{ $t('content.accelerating.academy.OtherInfo.txt07') }}
              <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
            </span>
            <span class="list-data">
              <!-- HEALTH MONITORING -->
              <template
                v-if="accInfo.code_support_item[0] === '1'"
              >
                <span class="block">{{$t('content.accelerating.academy.OtherInfo.txt32')}}</span>

                <!-- 운전자 감정/건강 정보 수집 -->
                <span
                  v-if="accInfo.code_support_corporateitem[0] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt36')}}</span>

                <!-- 외부 환경 정보 수집 -->
                <span
                  v-if="accInfo.code_support_corporateitem[1] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt37')}}</span>
              </template>
              <!-- // HEALTH MONITORING -->

              <!-- HEALTH DATA ANALYTICS -->
              <template
                v-if="accInfo.code_support_item[1] === '1'"
              >
                <span class="block">{{$t('content.accelerating.academy.OtherInfo.txt33')}}</span>

                <!-- 식단 분석 -->
                <span
                  v-if="accInfo.code_support_corporateitem[0] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt38')}}</span>

                <!-- 영양소 분석 -->
                <span
                  v-if="accInfo.code_support_corporateitem[1] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt39')}}</span>

                <!-- HEART RATE -->
                <span
                  v-if="accInfo.code_support_corporateitem[2] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt40')}}</span>

                <!-- BODY MASS -->
                <span
                  v-if="accInfo.code_support_corporateitem[3] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt41')}}</span>

                <!-- 혈당측정/분석 -->
                <span
                  v-if="accInfo.code_support_corporateitem[4] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt42')}}</span>

                <!-- 수면 분석 -->
                <span
                  v-if="accInfo.code_support_corporateitem[5] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt43')}}</span>
              </template>
              <!-- // HEALTH DATA ANALYTICS -->

              <!-- IN-CAR CARE SOLUTION -->
              <template
                v-if="accInfo.code_support_item[2] === '1'"
              >
                <span class="block">{{$t('content.accelerating.academy.OtherInfo.txt34')}}</span>

                <!-- 건강 테라피 콘텐츠 -->
                <span
                  v-if="accInfo.code_support_corporateitem[0] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt44')}}</span>

                <!-- 심리 상담 및 케어 기능 -->
                <span
                  v-if="accInfo.code_support_corporateitem[1] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt45')}}</span>

                <!-- 감정 상태 맞춤형 실내 환경 -->
                <span
                  v-if="accInfo.code_support_corporateitem[2] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt46')}}</span>

                <!-- 장애 보조 실내 기능 -->
                <span
                  v-if="accInfo.code_support_corporateitem[3] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt47')}}</span>

                <!-- 운동/자세 보조 기능 -->
                <span
                  v-if="accInfo.code_support_corporateitem[4] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt48')}}</span>
              </template>
              <!-- // IN-CAR CARE SOLUTION -->

              <!-- CONNECTED CARE SERVICE -->
              <template
                v-if="accInfo.code_support_item[3] === '1'"
              >
                <span class="block">{{$t('content.accelerating.academy.OtherInfo.txt35')}}</span>

                <!-- 병원/약국 접근성 -->
                <span
                  v-if="accInfo.code_support_corporateitem[0] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt49')}}</span>

                <!-- 맞춤형 명소 추천/안내 -->
                <span
                  v-if="accInfo.code_support_corporateitem[1] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt50')}}</span>

                <!-- 보험 연계 -->
                <span
                  v-if="accInfo.code_support_corporateitem[2] === '1'"
                  class="block"
                >- {{$t('content.accelerating.academy.OtherInfo.txt51')}}</span>
              </template>
              <!-- // CONNECTED CARE SERVICE -->

            </span>
          </template>
          <!-- // 원하는 지원 항목 : (AC114) Corporate ACC-Hyundai인 경우 -->

          <!-- 원하는 지원 항목 : (AC115) Corporate ACC-Eisai인 경우 -->
          <template v-else-if="accApplyPgmMngDetail.accKind === 'AC115'">
            <span class="list-title">
              {{ $t('content.accelerating.academy.OtherInfo.txt07') }}
            </span>
            <span class="list-data">
              <!-- 인지기능 스크리닝 -->
              <span
                v-if="accInfo.code_support_item[0] === '1'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt66')}}</span>
              <!-- 인지기능 트레이닝 -->
              <span
                v-if="accInfo.code_support_item[1] === '1'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt67')}}</span>
              <!-- 식이요법 -->
              <span
                v-if="accInfo.code_support_item[2] === '1'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt68')}}</span>
              <!-- 운동요법 -->
              <span
                v-if="accInfo.code_support_item[3] === '1'"
                class="block"
              >{{$t('content.accelerating.academy.OtherInfo.txt69')}}</span>
            </span>
          </template>
          <!-- // 원하는 지원 항목 : (AC115) Corporate ACC-Eisai인 경우 -->

          <!-- 원하는 지원 항목 : 그 이외의 일반적인 경우 -->
          <template v-else>
            <template v-if="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1">
            <span class="list-title">
                {{ $t('content.accelerating.academy.OtherInfo.txt07') }}
                <em
                  v-if="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                  class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
              </span>
              <span class="list-data">
                <!-- 법무지원(각종 법률검토, 특허출원 등) -->
                <span
                  v-if="accInfo.code_support_item[0] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt70')}}</span>
                <!-- 세무/회계 -->
                <span
                  v-if="accInfo.code_support_item[1] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt71')}}</span>
                <!-- 홍보/마케팅 -->
                <span
                  v-if="accInfo.code_support_item[2] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt72')}}</span>
                <!-- 개발 검수/지원 -->
                <span
                  v-if="accInfo.code_support_item[3] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt73')}}</span>
                <!-- 해외진출(아시아 위주) -->
                <span
                  v-if="accInfo.code_support_item[4] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt74')}}</span>
                <!-- 금융사 연계 등의 사업 지원 -->
                <span
                  v-if="accInfo.code_support_item[5] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt75')}}</span>
                <!-- BM 강화 -->
                <span
                  v-if="accInfo.code_support_item[6] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt76')}}</span>
                <!-- 투자 유치 -->
                <span
                  v-if="accInfo.code_support_item[7] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt77')}}</span>
                <!-- DAO -->
                <span
                  v-if="accInfo.code_support_item[8] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt78')}}</span>
                <!-- SECURITY -->
                <span
                  v-if="accInfo.code_support_item[9] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt79')}}</span>
              </span>
            </template>
            <template v-else>
              <span class="list-title">
                {{ $t('content.accelerating.academy.OtherInfo.txt07') }}
              </span>
              <span class="list-data">
                <!-- 법무지원(각종 법률검토, 특허출원 등) -->
                <span
                  v-if="accInfo.code_support_item[0] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt08')}}</span>
                <!-- 세무/회계 -->
                <span
                  v-if="accInfo.code_support_item[1] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt09')}}</span>
                <!-- 홍보/마케팅 -->
                <span
                  v-if="accInfo.code_support_item[2] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt10')}}</span>
                <!-- 개발 검수/지원 -->
                <span
                  v-if="accInfo.code_support_item[3] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt11')}}</span>
                <!-- 해외진출(아시아 위주) -->
                <span
                  v-if="accInfo.code_support_item[4] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt12')}}</span>
                <!-- 금융사 연계 등의 사업 지원 -->
                <span
                  v-if="accInfo.code_support_item[5] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt13')}}</span>
                <!-- BM 강화 -->
                <span
                  v-if="accInfo.code_support_item[6] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt14')}}</span>
                <!-- 투자 유치 -->
                <span
                  v-if="accInfo.code_support_item[7] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt15')}}</span>
                <!-- 사무공간 -->
                <span
                  v-if="accInfo.code_support_item[8] === '1'"
                  class="block"
                >{{$t('content.accelerating.academy.OtherInfo.txt16')}}</span>
              </span>
            </template>

          </template>
          <!-- // 원하는 지원 항목 : 그 이외의 일반적인 경우 -->
        </li>
        <!-- // 원하는 지원 항목 -->

        <!-- 지원동기 및 포부 -->
        <!-- * (AC107) Corporate ACC-Eland인 경우에는 : 지원동기 및 포부 필수입력 -->
        <li
          v-if="accApplyPgmMngDetail.supportPlanYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.OtherInfo.txt17') }}
            <em
              v-if="accApplyPgmMngDetail.accKind === 'AC107'"
              class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <span style="white-space: pre-line">{{ accInfo.support_plan || '-' }}</span>
          </span>
        </li>

        <!-- 지원경로 -->
        <li
          v-if="accApplyPgmMngDetail.supportPathYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.OtherInfo.txt52') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <!-- SNS -->
            <span v-if="accInfo.support_path === 'AC401'">
              {{ $t('content.accelerating.academy.OtherInfo.txt54') }}
            </span>
            <!-- 스타트업 매체 -->
            <span v-if="accInfo.support_path === 'AC402'">
              {{ $t('content.accelerating.academy.OtherInfo.txt55') }}
            </span>
            <!-- 오프라인 포스터 -->
            <span v-if="accInfo.support_path === 'AC403'">
              {{ $t('content.accelerating.academy.OtherInfo.txt56') }}
            </span>
            <!-- 외부추천 -->
            <span v-if="accInfo.support_path === 'AC404'">
              {{ $t('content.accelerating.academy.OtherInfo.txt57') }}
            </span>
            <!-- 기타 -->
            <span v-if="accInfo.support_path === 'AC405'">
              {{ $t('content.accelerating.academy.OtherInfo.txt58') }}
            </span>
            <!-- 기타 -->
            <span v-if="accInfo.support_path === 'AC406'">
              {{ $t('content.accelerating.academy.OtherInfo.txt590') }}
            </span>
            <!-- 외부추천, 기타 지원경로 (직접입력) -->
            <span v-if="(accInfo.support_path === 'AC404' || accInfo.support_path === 'AC405')
              && accInfo.support_path_sub">({{ accInfo.support_path_sub }})</span>
          </span>
        </li>
        <!-- // 지원경로 -->

        <!-- 기타 입력1 -->
        <!-- * (AC107) Corporate ACC-Eland인 경우에는 : 기타 입력1 필수입력 -->
        <li
          v-if="accApplyPgmMngDetail.etcInputYn"
        >
          <span class="list-title">
            {{ accApplyPgmMngDetail.etcInputTitle }}
            <em
              v-if="accApplyPgmMngDetail.accKind === 'AC107' || accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
              class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <span style="white-space: pre-line">{{ accInfo.etc_input }}</span>
          </span>
        </li>

        <!-- 기타 입력2 -->
        <li
          v-if="accApplyPgmMngDetail.etcInput2Yn"
        >
          <span class="list-title">{{ accApplyPgmMngDetail.etcInputTitle2 }}
            <em
              v-if="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
              class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <span style="white-space: pre-line">{{ accInfo.etc_input2 }}</span>
          </span>
        </li>
        <li
          v-if="accApplyPgmMngDetail.etcInput3Yn"
        >
          <span class="list-title">{{ accApplyPgmMngDetail.etcInputTitle3 }}
            <em
              v-if="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
              class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
            </span>
          <span class="list-data">
            <span style="white-space: pre-line">{{ accInfo.etc_input3 }}</span>
          </span>
        </li>
        <li
          v-if="accApplyPgmMngDetail.etcInput4Yn"
        >
          <span class="list-title">{{ accApplyPgmMngDetail.etcInputTitle4 }}</span>
          <span class="list-data">
            <span style="white-space: pre-line">{{ accInfo.etc_input4 }}</span>
          </span>
        </li>
                <li
          v-if="accApplyPgmMngDetail.etcInput5Yn"
        >
          <span class="list-title">{{ accApplyPgmMngDetail.etcInputTitle5 }}</span>
          <span class="list-data">
            <span style="white-space: pre-line">{{ accInfo.etc_input5 }}</span>
          </span>
        </li>

        <!-- 참가 서약서 동의 -->
        <li
          v-if="accApplyPgmMngDetail.entryAgreeYn"
        >
          <span class="list-title">
            {{ $t('content.accelerating.academy.DisplayInfo.txt08') }}
            <em class="required-mark">{{ $t('content.networking.CompanyInfo.txt10') }}</em>
          </span>
          <span class="list-data">
            <span v-if="accInfo.entry_agree === '1'">
              {{$t('content.accelerating.academy.DisplayInfo.txt09')}}
            </span>
            <span v-if="accInfo.entry_agree === '0'">
              {{$t('content.accelerating.academy.DisplayInfo.txt10')}}
            </span>
          </span>
        </li>
      </ul>
    </div>
    <!-- // 5단계 : 기타입력 -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils, format } from '@/common/helperUtils';
import { FilePaths } from '@/common/portalApi';

/**
 * Programs > 공모지원 & 공모지원내역 : 지원서 정보 (컴포넌트)
 */
export default {
  name: 'ProgramsApplyInfo',
  mixins: [mixinHelperUtils],
  props: {
    /**
     * Acc지원프로그램 관리 상세
     * @type {import('./dto/AccApplyPgmMngDetailDto').default}
     */
    accApplyPgmMngDetail: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 지원정보 (회사정보, 서비스, 기타입력 등)
     * @type {import('./dto/apply/AccInfoDto').default} AccInfoDto
     * @see AccApplyDto
     */
    accInfo: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 대표자, 공동대표
     * @type {import('./dto/apply/AccCeoInfoDto').default[]}
     * @see AccApplyDto
     */
    accCeoInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 수상경력
     * @type {import('./dto/apply/AccAwardCareerInfoDto').default[]}
     * @see AccApplyDto
     */
    accAwardCareerInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 보유 지적 재산권
     * @type {import('./dto/apply/AccPropertyInfoDto').default[]}
     * @see AccApplyDto
     */
    accPropertyInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 보유 허가 및 등록 현황
     * @type {import('./dto/apply/AccLicenseInfoDto').default[]}
     * @see AccApplyDto
     */
    accLicenseInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 멤버
     * @type {import('./dto/apply/AccMemberInfoDto').default[]}
     * @see AccApplyDto
     */
    accMemberInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 창업 프로그램 참여경험
     * @type {import('./dto/apply/AccStartPgJoinExpDto').default[]}
     * @see AccApplyDto
     */
    accStartPgJoinExp: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 멘토
     * @type {import('./dto/apply/AccMentorInfoDto').default[]}
     * @see AccApplyDto
     */
    accMentorInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 투자유치 현황
     * @type {import('./dto/apply/AccInvestInfoDto').default[]}
     * @see AccApplyDto
     */
    accInvestInfo: {
      type: Array,
      required: true,
    },
  },

  computed: {
    /**
     * 설립연도 (포맷팅)
     */
    buildDate() {
      if (this.accInfo.build_year && this.accInfo.build_month && this.accInfo.build_day) {
        return this.formatDate(`${this.accInfo.build_year}-${this.accInfo.build_month}-${this.accInfo.build_day}`);
      }
      return '-';
    },
    /**
     * 대표자 연락처 (포맷팅)
     */
    ceoCellphone() {
      if (this.accInfo.ceo_cellphone_first && this.accInfo.ceo_cellphone_sec) {
        return format.phone(`${this.accInfo.ceo_cellphone_first}${this.accInfo.ceo_cellphone_sec}`);
      }
      return '-';
    },
  },

  methods: {
    /**
     * 기타입력 첨부파일 다운로드
     */
    downloadEtcInfoFile(file, fileName) {
      const path = this.getFileURL(FilePaths.ACCELERATING, file); // mixinHelperUtils

      this.downloadFile(path, fileName); // mixinHelperUtils
    },

    /**
     * 일자 포맷팅
     * @see {@link https://momentjs.com/docs/#/displaying/format/ Localized formats}
     */
    formatDate(value) {
      const d = this.$moment(value, 'YYYY-MM-DD');
      return d.isValid() ? d.format('LL') : '-';
    },
  },
};
</script>
<style>
.form-list > ul > li {
  justify-content: initial;
}
</style>
